import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import GridLines from "../components/gridlines"
import Timeline from "../components/timeline"
import { Link } from "gatsby"

const AgendaPage = () => (
  <Background>
    <Layout>
      <SEO title="Agenda" />
      <Container>
        <PageTitle text="Agenda" />
        <Link to="/register/" className="d-none d-md-block" style={{
          position: `fixed`,
          right: `0`,
          top: `85%`,
          backgroundColor: `#fff`,
          borderColor: `#fff`,
          color: `#d23464`,
          textTransform: `uppercase`,
          borderRadius: `0`,
          padding: `.6rem 1rem`,
          fontWeight: `600`,
          zIndex: `500`
        }}>
          Ready To<br />Register?
        </Link>
        <GridLines gridColor="#d23464">
          <div style={{
              fontFamily: `Din Pro`,
              color: `#fff`,
              background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
              padding: `2rem`,
              margin: `0`
          }}>
            <Timeline />
          </div>
        </GridLines>
      </Container>
      <br />
      <br />
      <br />
    </Layout>
  </Background>
)

export default AgendaPage
